import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addItem", "template", "name", "output" ]

  addAssociation(event) {
    event.preventDefault()  
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.addItemTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeAssociation(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
  
  greetings() {
    event.preventDefault()
    this.outputTarget.textContent =
    `Hello, ${this.nameTarget.value}!`
    
  }
}